@charset "utf-8";

@import '../_inc/scss/variables.scss';
@import '../_inc/scss/mixins.scss';

.main {
  border-top: none;
}

.login-form {
    margin: auto;
    max-width: 360px;
}

.form-parts {
    margin: 1em 0;
    width: 100%;
    > * {
        padding: 6px;
        width: 100%;
    }
}