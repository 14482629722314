@mixin fontCalc($num) {
  $rem: $num / 16;
  font-size: #{$rem}rem;
}

@mixin mincho {
  font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mqParam($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}